const menuTogglers = document.querySelectorAll('[data-menu-toggle]');
const sidebar = document.querySelector('.sidebar');
const body = document.querySelector('body');

for (let i = 0; i < menuTogglers.length; i++) {
    menuTogglers[i].addEventListener('click', function (event) {
        event.preventDefault();
        toggleMenu();
    });
}

function toggleMenu () {
    sidebar.classList.toggle('is-active');
    body.classList.toggle('overflow-hidden');
}
